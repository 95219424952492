<template>
  <div class="verification-form-wrapper">
    <div class="verification-form">
      <header>
        <div
          class="shopqr-detail"
          v-if="shopDetails"
        >
          <div class="shop-image">
            <img :src="shopDetails.image" />
          </div>
          <div
            class="shop-detail"
            style="z-index: 1"
          >
            <p class="shop-name">
              {{ shopDetails.name }}
            </p>
            <p class="address">
              <span
                class="material-icons"
                style="font-size: 1.25em"
              >location_on</span>
              {{
                shopDetails.address.street +
                " " +
                shopDetails.address.city +
                " " +
                shopDetails.address.zip_code
              }}
            </p>
          </div>
        </div>
        <span
          class="closePopUp"
          @click="$router.go(-1)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 23 23"
          >
            <path
              fill="#CDCDCD"
              fill-rule="nonzero"
              d="M19.636 3.364C15.15-1.12 7.85-1.122 3.364 3.364c-4.486 4.487-4.485 11.785 0 16.271 4.486 4.487 11.785 4.487 16.272 0 4.485-4.486 4.485-11.785 0-16.27zm-3.599 12.673a.885.885 0 0 1-1.251 0L11.5 12.75l-3.442 3.442a.885.885 0 1 1-1.251-1.251l3.442-3.442-3.286-3.286a.885.885 0 1 1 1.251-1.251l3.286 3.286 3.13-3.13a.885.885 0 1 1 1.25 1.252L12.753 11.5l3.285 3.286a.885.885 0 0 1 0 1.25z"
            />
          </svg>
        </span>
      </header>
      <div class="form-item">
        <div
          class="bottomToUp"
          v-if="isSampleVideo"
        ></div>
        <div
          class="bottomToUp_content item-deliver"
          :class="{ activeBottomtoUp: isSampleVideo }"
          v-if="isSampleVideo"
        >
          <div
            class="price-details"
            style="background: #fff"
          >
            <h2 style="font-size: 0.938em">Watch Sample Video</h2>
            <div
              class="item-detail"
              style="padding-top: 0px; height: 200px"
            >
              <!-- <video src="https://www.youtube.com/watch?v=Ht1A6F53KeU" controls style="max-width:100%"></video> -->
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/Ht1A6F53KeU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                style="max-width: 100%; max-height: 100%"
              ></iframe>
            </div>
          </div>
          <span
            class="closePopUp"
            @click="isSampleVideo = !isSampleVideo"
            style="top: 5px"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              viewBox="0 0 23 23"
            >
              <path
                fill="#CDCDCD"
                fill-rule="nonzero"
                d="M19.636 3.364C15.15-1.12 7.85-1.122 3.364 3.364c-4.486 4.487-4.485 11.785 0 16.271 4.486 4.487 11.785 4.487 16.272 0 4.485-4.486 4.485-11.785 0-16.27zm-3.599 12.673a.885.885 0 0 1-1.251 0L11.5 12.75l-3.442 3.442a.885.885 0 1 1-1.251-1.251l3.442-3.442-3.286-3.286a.885.885 0 1 1 1.251-1.251l3.286 3.286 3.13-3.13a.885.885 0 1 1 1.25 1.252L12.753 11.5l3.285 3.286a.885.885 0 0 1 0 1.25z"
                opacity=".675"
              />
            </svg>
          </span>
        </div>
        <div class="item-info flx">
          <p class="info-heading">
            <span>Shop Video <sup>*</sup></span><span class="label-text">अधिकतम 15 सेकंड का विडियो बनाये</span>
          </p>
          <span
            class="video-sample-btn flx"
            @click="isSampleVideo = !isSampleVideo"
          >
            <i class="material-icons">arrow_right</i> सैंपल देखें
          </span>
        </div>
        <div
          class="insert-image"
          v-if="!shopVideoPreview"
        >
          <label class="file">
            <span
              class="material-icons video-icons"
              v-if="cookie <= '98'"
            >video_camera_front</span>
            <span
              class="material-icons video-icons"
              v-if="cookie >= '99'"
              @click="jsBridgeUploadVideo"
            >video_camera_front</span>
            <div class="file-custom">
              <input
                type="file"
                id="shopVideo1"
                ref="myFiles"
                accept="video/*"
                @change="uploadVideo"
                multiple
                v-if="cookie <= '98'"
              />
              <span
                class="upload_photo"
                v-if="cookie <= '98'"
              >वीडियो बनाएं</span>
              <span
                class="upload_photo"
                @click="jsBridgeUploadVideo"
                v-if="cookie >= '99'"
              >वीडियो बनाएं</span>
            </div>
          </label>
        </div>
        <div
          class="insert-image video-added"
          v-if="shopVideoPreview && cookie <= '98'"
          style="padding: 0"
        >
          <label
            class="file"
            :class="{
              videofile:
                !getShopDocument.status ||
                (getShopDocument.status &&
                  getShopDocument.status === 'REJECTED'),
            }"
          >
            <div
              class="file-custom"
              :class="{
                disabled:
                  !getShopDocument.shopVideoUrl ||
                  (getShopDocument.status &&
                    getShopDocument.status !== 'REJECTED'),
              }"
            >
              <input
                type="file"
                id="shopVideo2"
                ref="myFiles"
                accept="video/*"
                @change="uploadVideo"
                multiple
              />
            </div>
            <video
              :src="shopVideoPreview"
              controls
              @change="uploadVideo($event)"
            ></video>
          </label>
        </div>
        <div
          class="insert-image video-added"
          v-if="shopVideoPreview && cookie >= '99'"
          style="padding: 0"
          :class="{
            disabled:
              !getShopDocument.shopVideoUrl ||
              (getShopDocument.status && getShopDocument.status !== 'REJECTED'),
          }"
        >
          <label
            class="file"
            :class="{
              videofile:
                !getShopDocument.status ||
                (getShopDocument.status &&
                  getShopDocument.status === 'REJECTED'),
            }"
          >
            <div
              class="file-custom"
              @click="jsBridgeUploadVideo()"
            ></div>
            <div
              class="video-container"
              style="width: 70%; height: 144px; margin: 0 auto"
            >
              <video
                :src="shopVideoPreview"
                controls
                style="width: 100%; height: 100%"
              ></video>
            </div>
          </label>
        </div>
      </div>
      <div class="form-item">
        <div class="item-info flx">
          <p class="info-heading">
            <span>Shop Selfie <sup>*</sup></span><span class="label-text">दुकान के पास खड़े होकर अपनी सेल्फी क्लिक करें</span>
          </p>
        </div>
        <div
          class="insert-image"
          v-if="!shopSelfiePreview"
        >
          <label class="file">
            <span class="material-icons video-icons">photo_camera</span>
            <div class="file-custom">
              <input
                type="file"
                id="file"
                ref="myFiles"
                accept="image/*"
                @change="uploadPhoto"
                multiple
              />
              <span class="upload_photo">सेल्फी क्लिक करें</span>
            </div>
          </label>
        </div>
        <div
          class="insert-image"
          v-if="shopSelfiePreview"
          style="padding: 0"
        >
          <label
            class="file"
            :class="{
              disabled:
                !getShopDocument.shopVideoUrl ||
                (getShopDocument.status &&
                  getShopDocument.status !== 'REJECTED'),
            }"
          >
            <img
              :src="shopSelfiePreview"
              :alt="shopSelfiePreview"
            />
            <div class="file-custom">
              <input
                type="file"
                id="shopPhotofile"
                ref="myFiles"
                accept="image/*"
                @change="uploadPhoto"
                multiple
              />
            </div>
          </label>
        </div>
      </div>
      <div class="form-item">
        <div class="item-info flx">
          <p
            class="info-heading"
            v-if="getShopDocument.shopVideoUrl"
          >
            <span>Identification Card </span><span class="label-text">
              <select
                v-model="getShopDocument.identityCardType"
                class="form-control cstm_field"
                required
                :disabled="
                  getShopDocument.status &&
                  getShopDocument.status !== 'REJECTED'
                "
              >
                <option
                  v-for="(idCardType, index) in identificationCardType"
                  :value="idCardType.name"
                  :key="index"
                >
                  {{ idCardType.value }}
                </option>
              </select>
            </span>
          </p>
          <p
            class="info-heading"
            v-else
          >
            <span>Identification Card </span><span class="label-text">
              <select
                v-model="getShopDocument.identityCardType"
                class="form-control cstm_field"
                required
              >
                <option
                  v-for="(idCardType, index) in identificationCardType"
                  :value="idCardType.name"
                  :key="index"
                >
                  {{ idCardType.value }}
                </option>
              </select>
            </span>
          </p>
        </div>
        <!-- <identification-card-type :getDocumentDetail='getShopDocument' :selectedIdCardType='getShopDocument.identityCardType' /> -->
        <div v-if="getShopDocument.identityCardType">
          <div
            class="insert-image"
            v-if="!identityCardPreview"
          >
            <label class="file">
              <span class="material-icons video-icons">photo_camera</span>
              <div class="file-custom">
                <input
                  type="file"
                  id="isidentityCard"
                  ref="myFiles"
                  accept="image/*"
                  @change="uploadIdentityCard"
                  multiple
                />
                <span class="upload_photo">Upload document image</span>
              </div>
            </label>
          </div>
          <div
            class="insert-image"
            v-if="identityCardPreview"
            style="padding: 0"
          >
            <label
              class="file"
              :class="{
                disabled:
                  getShopDocument.shopVideoUrl ||
                  (getShopDocument.status &&
                    getShopDocument.status !== 'REJECTED'),
              }"
            >
              <img
                :src="identityCardPreview"
                :alt="identityCardPreview"
              />
              <div class="file-custom">
                <input
                  type="file"
                  id="identityCard"
                  ref="myFiles"
                  accept="image/*"
                  @change="uploadIdentityCard"
                  multiple
                />
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="form-item">
        <div class="item-info flx">
          <p
            class="info-heading"
            v-if="getShopDocument.shopVideoUrl"
          >
            <span>Address Proof</span><span class="label-text">
              <select
                v-model="getShopDocument.addressProofType"
                class="form-control cstm_field"
                required
                :disabled="
                  getShopDocument.status &&
                  getShopDocument.status !== 'REJECTED'
                "
              >
                <option
                  v-for="(addressProof, index) in addressProofType"
                  :value="addressProof.name"
                  :key="index"
                >
                  {{ addressProof.value }}
                </option>
              </select>
            </span>
          </p>
          <p
            class="info-heading"
            v-else
          >
            <span>Address Proof</span><span class="label-text">
              <select
                v-model="getShopDocument.addressProofType"
                class="form-control cstm_field"
                required
              >
                <option
                  v-for="(addressProof, index) in addressProofType"
                  :value="addressProof.name"
                  :key="index"
                >
                  {{ addressProof.value }}
                </option>
              </select>
            </span>
          </p>
        </div>
        <!-- <identification-card-type :getDocumentDetail='getShopDocument' :selectedIdCardType='getShopDocument.identityCardType' /> -->
        <div v-if="getShopDocument.addressProofType">
          <div
            class="insert-image"
            v-if="!addressProofPreview"
          >
            <label class="file">
              <span class="material-icons video-icons">photo_camera</span>
              <div class="file-custom">
                <input
                  type="file"
                  id="isAddressProof"
                  ref="myFiles"
                  accept="image/*"
                  @change="uploadAddressProofCard"
                  multiple
                />
                <span class="upload_photo">Upload document image</span>
              </div>
            </label>
          </div>
          <div
            class="insert-image"
            v-if="addressProofPreview"
            style="padding: 0"
          >
            <label
              class="file"
              :class="{
                disabled:
                  !getShopDocument.shopVideoUrl ||
                  (getShopDocument.status &&
                    getShopDocument.status !== 'REJECTED'),
              }"
            >
              <img
                :src="addressProofPreview"
                :alt="addressProofPreview"
              />
              <div class="file-custom">
                <input
                  type="file"
                  id="AddressProof"
                  ref="myFiles"
                  accept="image/*"
                  @change="uploadAddressProofCard"
                  multiple
                />
              </div>
            </label>
          </div>
        </div>
      </div>
      <!-- <div class="form-item">
        <div class="item-info flx">
          <p class="info-heading"><span>Comments</span></p>
        </div>
        <div class="add-input">
          <label class="file">
            <textarea type="text" v-model="getShopDocument.comments" rows="4"  placeholder="Write something about shop " :readonly="!getShopDocument.shopVideoUrl || getShopDocument.status==='REJECTED'"></textarea>
          </label>
        </div>
      </div> -->
    </div>

    <!-- <div
      class="bottom-btn"
      @click="updateShopVerification()"
      v-if="
        (!getShopDocument.status ||
          (getShopDocument.status && getShopDocument.status === 'REJECTED')) &&
        $route.query.shopid
      "
    >
      <button type="button" class="submit-btn">UPDATE</button>
    </div> -->
    <div
      class="bottom-btn"
      @click="updateShopVerification()"
    >
      <button
        type="button"
        class="submit-btn"
      >UPDATE</button>
    </div>
    <div
      class="loader"
      v-if="loading"
    >
      <img
        src="../assets/images/loader.gif"
        alt="loader"
      />
    </div>
    <!-- <CustomToaster :dataValue="toasterData" v-if="isValidDetail" /> -->
    <div
      id="cart-snackbar"
      :class="{ show: isErrorExist }"
    >
      <div
        class="flx"
        v-if="errorFieldString !== ''"
      >
        <p style="width: 100%; text-align: center">{{ errorFieldString }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import  from "../utils/s";
// import {
//   // uploadImageFile,
//   getBase64Url,
// } from "../utils/helper";
// import IdentificationCardType from "./IdentificationCardType.vue";
import ApiCaller from "../services/ApiRequest";
import { BASE_API, FILE_UPLOAD_URL_OLD } from "../utils/constants";
import { getBase64Url } from "../utils/Helper";
import { SessionHelper } from "../services/Session";
import { mapGetters } from "vuex";
// import CustomToaster from "../Common/CustomToaster";

export default {
  name: "VerifyForm",
  components: {
    // IdentificationCardType,
    // CustomToaster,
  },
  data() {
    return {
      shopSelfiePreview: "",
      shopVideoPreview: "",
      identityCardFile: [],
      identityCardPreview: "",
      gstNumber: null,
      addressProofPreview: "",
      getShopDocument: {},
      isValidDetail: false,
      toasterData: {},
      cookie: SessionHelper.getCookie("version-code"),
      isSampleVideo: false,
      identificationCardType: [
        { name: "AADHAAR_CARD", value: "Aadhar Card" },
        { name: "PASSPORT", value: "Passport" },
        { name: "PAN_CARD", value: "PanCard" },
        { name: "DRIVING_LICENSE", value: "Driving License" },
        { name: "RATION_CARD", value: "Ration card" },
        { name: "VOTER_ID", value: "Voter Id" },
      ],
      addressProofType: [
        { name: "AADHAAR_CARD", value: "Aadhar Card" },
        { name: "RATION_CARD", value: "Ration card" },
        { name: "DRIVING_LICENSE", value: "Driving license" },
        { name: "VOTER_ID", value: "Voter Id" },
        { name: "UTILITY_BILLS", value: "Utility bills" },
        { name: "RENTAL_AGREEMENT", value: "Rentel agreement" },
        { name: "PASSPORT", value: "Passport" },
        { name: "BANK_PASSBOOK", value: "Bank passbook" },
      ],
      loading: false,
      isErrorExist: false,
      errorFieldString: "",
    };
  },
  props: {
    getShopQrDetail: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters({
      shopDetails: "getShopDetails",
    }),
  },

  mounted() {
    // this.getShopVerificationDocument();
    console.log(this.shopDetails);
    if (!this.shopDetails) {
      this.$router.push("/shops");
    }
  },

  methods: {
    jsBridgeUploadVideo() {
      //  this.shopVideoPreview = "https://money91kxtvk2qmhk.cdn.e2enetworks.net/bbc123c7-b723-4071-916f-1b1a7d2382c4"
      this.loading = true;
      try {
        window._getCamera.pickCompressVideo = (success, outputFilePath) => {
          console.log(success, outputFilePath);
          // if(success.includes('Videos'))
          if (success === "COMPRESSION_SUCCESS") {
            this.loading = false;
            if (outputFilePath.includes(".mp4")) {
              setTimeout(() => {
                this.shopVideoPreview = outputFilePath;
                this.getShopDocument.shopVideoUrl = outputFilePath;
              }, 1000);
            }
          } else if (success === "INVALID_VIDEO") {
            this.loading = false;
            this.toasterData = {
              msg: "Please select valid video",
              color: "#e51c24",
              iconName: "close",
            };
            this.isValidDetail = true;
          } else if (success === "EXCEPTION_COMPRESSING") {
            this.loading = false;
            this.toasterData = {
              msg: "Video quality is too high",
              color: "#e51c24",
              iconName: "close",
            };
            this.isValidDetail = true;
          } else if (success === "SOURCE_PATH_NULL") {
            this.loading = false;
            this.toasterData = {
              msg: "Please select correct video",
              color: "#e51c24",
              iconName: "close",
            };
            this.isValidDetail = true;
          } else if (success === "ERROR_CREATING_DESTINATION_PATH") {
            this.loading = false;
            this.toasterData = {
              msg: "File path is not valid",
              color: "#e51c24",
              iconName: "close",
            };
            this.isValidDetail = true;
          } else if (success === "DESTINATION_FILE_CORRUPTED") {
            this.loading = false;
            this.toasterData = {
              msg: "Destination file corrupted",
              color: "#e51c24",
              iconName: "close",
            };
            this.isValidDetail = true;
          } else if (success === "STORAGE_PERMISSION_DENIED") {
            this.loading = false;
            this.toasterData = {
              msg: "Your permission denied",
              color: "#e51c24",
              iconName: "close",
            };
            this.isValidDetail = true;
          } else if (success === "PICK_VIDEO_CANCELLED") {
            this.loading = false;
            // this.toasterData = {
            //   msg: 'Your picked video is cancelled',
            //   color: '#e51c24',
            //   iconName: 'close',
            // };
            // this.isValidDetail = true;
          } else if (success === "PICK_VIDEO_RESULT_NULL") {
            this.loading = false;
            this.toasterData = {
              msg: "Video result not found",
              color: "#e51c24",
              iconName: "close",
            };
            this.isValidDetail = true;
          } else {
            this.loading = false;
            this.toasterData = {
              msg: "Please select another video",
              color: "#e51c24",
              iconName: "close",
            };
            this.isValidDetail = true;
          }
        };
        window.JSBridgePlugin.pickCompressVideo(
          3,
          "_getCamera.pickCompressVideo"
        );
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async uploadVideo(file) {
      const image = file.target.files[0];
      if (!image.type.includes("video/")) {
        alert("कृपया वीडियो अपलोड करें ।");
        return;
      }
      getBase64Url(image).then((data) => {
        this.shopVideoPreview = data;
      });
      const formData = new FormData();
      this.loading = true;
      formData.append("image", image, image.name);
      formData.append("isResizeRequired", true);
      await axios
        .post(FILE_UPLOAD_URL_OLD, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((data) => {
          this.getShopDocument.shopVideoUrl = data.data.mediaVariantDTOs[0].url;
          this.loading = false;
        })
        .catch((err) => {
          console.log("FAILURE", err);
        });
    },
    async uploadPhoto(file) {
      const image = file.target.files[0];
      if (!image.type.includes("image/")) {
        alert("Please select an image file");
        return;
      }
      getBase64Url(image).then((data) => {
        this.shopSelfiePreview = data;
      });
      const formData = new FormData();
      this.loading = true;
      formData.append("image", image, image.name);
      formData.append("isResizeRequired", true);
      await axios
        .post(FILE_UPLOAD_URL_OLD, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((data) => {
          this.getShopDocument.selfieUrl = data.data.mediaVariantDTOs[0].url;
          this.loading = false;
        })
        .catch((err) => {
          console.log("FAILURE", err);
        });
    },
    async uploadIdentityCard(file) {
      const image = file.target.files[0];
      if (!image.type.includes("image/")) {
        alert("Please select an image file");
        return;
      }
      getBase64Url(image).then((data) => {
        this.identityCardPreview = data;
      });
      const formData = new FormData();
      this.loading = true;
      formData.append("image", image, image.name);
      formData.append("isResizeRequired", true);
      await axios
        .post(FILE_UPLOAD_URL_OLD, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((data) => {
          this.getShopDocument.identityCard = data.data.mediaVariantDTOs[0].url;
          this.loading = false;
        })
        .catch((err) => {
          console.log("FAILURE", err);
        });
    },
    async uploadAddressProofCard(file) {
      const image = file.target.files[0];
      if (!image.type.includes("image/")) {
        alert("Please select an image file");
        return;
      }
      getBase64Url(image).then((data) => {
        this.addressProofPreview = data;
      });
      const formData = new FormData();
      this.loading = true;
      formData.append("image", image, image.name);
      formData.append("isResizeRequired", true);
      await axios
        .post(FILE_UPLOAD_URL_OLD, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((data) => {
          this.getShopDocument.addressProof = data.data.mediaVariantDTOs[0].url;
          this.loading = false;
        })
        .catch((err) => {
          console.log("FAILURE", err);
        });
    },
    validateFields() {
      const obj = this.getShopDocument;
      if (
        !obj.shopVideoUrl ||
        !obj.selfieUrl ||
        !obj.identityCardType ||
        !obj.identityCard ||
        !obj.addressProofType ||
        !obj.addressProof
        // !obj.comments
      ) {
        let fieldName = "";
        if (!obj.shopVideoUrl) {
          fieldName = "Please attach shop video";
        } else if (!obj.selfieUrl) {
          fieldName = "Please attach selfie with shop";
        } else if (!obj.identityCardType) {
          fieldName = "Please select any identity card";
        } else if (!obj.identityCard) {
          fieldName = "Please attach identity card image";
        } else if (!obj.addressProofType) {
          fieldName = "Please select any address proof";
        } else if (!obj.addressProof) {
          fieldName = "Please attach address proof image";
          // }else if(!obj.comments){
          //   fieldName = 'Tell us something about shop'
        }
        this.isErrorExist = !this.isErrorExist;
        this.errorFieldString = fieldName;
        setTimeout(() => {
          this.isErrorExist = !this.isErrorExist;
        }, 3000);
        // this.toasterData = {
        //   msg: `${fieldName}`,
        //   color: '#e51c24',
        //   iconName: 'close',
        // };
        // this.isValidDetail = true;
        return false;
      } else {
        // if (obj.comments.trim().length >= 4) {
        // } else {
        //   this.toasterData = {
        //     msg: 'Please write something about shop',
        //     color: '#e51c24',
        //     iconName: 'close',
        //   };
        //   this.isValidDetail = true;
        //   return false;
        // }
        return true;
      }
    },
    updateShopVerification() {
      console.log(this.shopDetails.shop_id);
      if (this.validateFields()) {
        this.loading = true;
        const obj = {
          ...this.getShopDocument,
          shopName: this.shopDetails.name,
          shopId: this.shopDetails.shop_id,
        };
        console.log(obj);
        // this.$set(this.getShopDocument, "status", "UNVERIFIED");
        ApiCaller(BASE_API, "/verified/shop/create", "post", obj)
          .then((data) => {
            this.loading = false;
            if (data && data.result && data.result.status) {
              this.$swal({
                text: "Shop details submitted successfully!",
                icon: "success",
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$router.push("/shops");
                }
              });
              this.isValidDetail = true;
            }
          })
          .catch((err) => {
            this.loading = false;
            if (err.response.data.message) {
              this.$swal({
                text: "Something went wrong!",
                icon: "error",
              });
              this.isValidDetail = true;
              return false;
            }
          });
      }
    },
    // getShopVerificationDocument() {
    //   if (
    //     SessionHelper.getCookie("X-Auth-Id") != null &&
    //     this.$route.query.shopid
    //   ) {
    //     this.loading = true;
    //     // let payloadObject;
    //     ApiCaller(
    //       LOCAL_SHOP_URL,
    //       `/get-shop-document?shopId=${this.$route.query.shopid}`,
    //       "get"
    //     )
    //       .then((data) => {
    //         this.getShopDocument = data.data;
    //         if (this.getShopDocument) {
    //           this.shopVideoPreview = this.getShopDocument.shopVideoUrl;
    //           this.shopSelfiePreview = this.getShopDocument.selfieUrl;
    //           this.identityCardPreview = this.getShopDocument.identityCard;
    //           this.addressProofPreview = this.getShopDocument.addressProof;
    //         }
    //         this.loading = false;
    //       })
    //       .catch((err) => {
    //         this.loading = false;
    //         if (
    //           err.response.data.message == "AUTHENTICATION_TOKEN_EXPIRED" ||
    //           err.response.data.message ==
    //             "AUTHENTICATION_TOKEN_EXPIRED_OR_INVALID"
    //         ) {
    //           try {
    //             window._JWT.refereshToken = (res) => {
    //               if (res != "fail") {
    //                 this.$cookie.set("JWT-TOKEN", res);
    //                 this.getShopVerificationDocument();
    //               } else {
    //                 this.loading = false;
    //               }
    //             };
    //             window.JSBridgePlugin.refreshTokens("window..refereshToken");
    //           } catch (err) {
    //             this.loading = false;
    //             console.log(err);
    //           }
    //         } else {
    //           console.log(err);
    //         }
    //       });
    //   } else {
    //     try {
    //       window._prompt_login.promptLogin = (res) => {
    //         if (res == "success") {
    //           console.log(res);
    //         } else {
    //           this.loading = false;
    //         }
    //       };
    //       window.JSBridgePlugin.promptLogin("_prompt_login.promptLogin");
    //     } catch (err) {
    //       this.loading = false;
    //       console.log(err);
    //     }
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
.videofile {
  position: relative;
  width: 100%;
  height: 145px;
  display: block;
  .file-custom {
    height: 145px;
    position: absolute;
    width: 100%;
  }
}
@import "@/assets/scss/verification-form.scss";
</style>
